<template>
    <div ref="formSection">
        <form class="uk-form-stacked form-custom" ref="form">
            <div class="h-mb--40" :class="'uk-alert-' + message.type" uk-alert v-for="message in messages" uk-alert>
                <a class="uk-alert-close" uk-close></a>
                <p>{{ message.message }}</p>
            </div>

            <div v-if="!hideForm">
                <fieldset class="">
                    <legend>{{ translations.interested_in }}</legend>
                
                    <div class="row">
                        <div class="col-md-7">
                            <div class="uk-grid-small" uk-grid>

                                <div v-for="interest in interests" class="uk-width-1-2@s uk-width-1-3@m">
                                    <label class="custom-checkbox custom-checkbox--big">
                                        <input type="checkbox" class="uk-checkbox" :id="interest" :value="interest" v-model="interestsChecked">
                                        <p class="my-0">{{interest}}</p>
                                    </label>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset class="h-mt--60">
                    <legend>{{ translations.your_brushes }} <span>({{ translations.optional }})</span></legend>

                    <div class="uk-grid-small" uk-grid>
                        <div v-for="(product, index) in productsSend" :key="product.id" class="uk-width-1-2@m">
                            <button :title="product.name" class="c-product-small" @click.prevent="removeProduct(index)">
                                <img :src="product.image"  class="c-product-small__image">
                                <span class="c-product-small__middel">
                                    <p class="c-product-small__title">{{product.name}}</p>
                                    <p class="c-product-small__nr">beo_02021</p>
                                </span>
                                <span class="c-product-small__remove"><i class="fa-regular fa-trash-alt"></i></span>
                            </button>
                        </div>
                    </div>
                </fieldset>

                <fieldset class="h-mt--60">
                    <legend>{{ translations.comments_title }}</legend>

                    <div class="uk-form-group uk-width-3-5@m">
                        <textarea name="comments" id="comments" cols="30" rows="4" class="uk-textarea" v-bind:class="{ 'uk-form-danger': errors.comments }" :placeholder="translations.comments_placeholder" v-model="comments"></textarea>
                        <p class="uk-text-danger mt-1" v-if="errors.comments" v-for="error in errors.comments">{{ error }}</p>
                    </div>
                </fieldset>

                <fieldset class="h-mt--60">
                    <legend>{{ translations.your_data }}</legend>
                
                    <div class="uk-grid-small" uk-grid>
                        <div class="uk-form-group uk-width-2-5@s">
                            <label for="firstname" class="uk-form-label" v-bind:class="{ 'uk-text-danger': errors.firstname }">{{ translations.firstname }}</label>
                            <input type="text" class="uk-input uk-form-control" v-bind:class="{ 'uk-form-danger': errors.firstname }" id="firstname" name="firstname" v-model="firstname">
                            <p class="uk-text-danger mt-1" v-if="errors.firstname" v-for="error in errors.firstname">{{ error }}</p>
                        </div>
                        <div class="uk-form-group uk-width-2-5@s">
                            <label for="lastname" class="uk-form-label" v-bind:class="{ 'uk-text-danger': errors.lastname }">{{ translations.lastname }}</label>
                            <input type="text" class="uk-input uk-form-control" v-bind:class="{ 'uk-form-danger': errors.lastname }" id="lastname" name="lastname" v-model="lastname">
                            <p class="uk-text-danger mt-1" v-if="errors.lastname" v-for="error in errors.lastname">{{ error }}</p>
                        </div>
                        <div class="uk-form-group uk-width-2-5@s">
                            <label for="company" class="uk-form-label" v-bind:class="{ 'uk-text-danger': errors.company }">{{ translations.company }} <span>({{ translations.optional }})</span></label>
                            <input type="text" class="uk-input uk-form-control" v-bind:class="{ 'uk-form-danger': errors.company }" id="company" name="company" v-model="company">
                            <p class="uk-text-danger mt-1" v-if="errors.company" v-for="error in errors.company">{{ error }}</p>
                        </div>
                        <div class="uk-form-group uk-width-2-5@s">
                            <label for="tax" class="uk-form-label" v-bind:class="{ 'uk-text-danger': errors.tax }">{{ translations.tax }} <span>({{ translations.optional }})</span></label>
                            <input type="text" class="uk-input uk-form-control" v-bind:class="{ 'uk-form-danger': errors.tax }" id="tax" name="tax" v-model="tax">
                            <p class="uk-text-danger mt-1" v-if="errors.tax" v-for="error in errors.tax">{{ error }}</p>
                        </div>
                        <div class="uk-form-group uk-width-2-5@s">
                            <label for="email" class="uk-form-label" v-bind:class="{ 'uk-text-danger': errors.email }">{{ translations.email }}</label>
                            <input type="email" class="uk-input uk-form-control" v-bind:class="{ 'uk-form-danger': errors.email }" id="email" name="email" v-model="email">
                            <p class="uk-text-danger mt-1" v-if="errors.email" v-for="error in errors.email">{{ error }}</p>
                        </div>
                        <div class="uk-form-group uk-width-2-5@s">
                            <label for="phone" class="uk-form-label" v-bind:class="{ 'uk-text-danger': errors.phone }">{{ translations.phone }}</label>
                            <input type="text" class="uk-input uk-form-control" v-bind:class="{ 'uk-form-danger': errors.phone }" id="phone" name="phone" v-model="phone">
                            <p class="uk-text-danger mt-1" v-if="errors.phone" v-for="error in errors.phone">{{ error }}</p>
                        </div>
                        <div class="uk-form-group uk-width-4-5 form-url" id="url_wrapper">
                            <label class="uk-form-label" for="url">Url</label>
                            <input class="uk-input uk-form-control" id="url" name="url" type="text" placeholder="Url" v-model="url">
                        </div>
                    </div>
                </fieldset>

                <fieldset class="h-mt--60">
                    <div class="d-flex flex-column align-items-start justify-content-between">
                        <div class="uk-form-group uk-width-4-5@s uk-width-3-5@m">
                            <label class="custom-checkbox"  v-bind:class="{ 'uk-form-danger': errors.privacy }" for="privacy">
                                <input class="uk-checkbox" id="privacy" name="privacy" type="checkbox" v-model="privacy">
                                <span class="t-cms t-text--small">
                                    <p>{{ translations.i_agree_with }} <a :href="routes.privacy_page" target="_blank" :title="translations.privacy_policy">{{ translations.privacy_policy }}</a></p>
                                </span>
                            </label>
                            <p class="uk-text-danger mt-1" v-if="errors.privacy" v-for="error in errors.privacy">{{ error }}</p>
                        </div>

                        <button class="uk-button uk-button-primary mt-4 " @click.prevent="handleFormSubmit">{{ translations.submit }}</button>
                    </div>
                </fieldset>

            </div>
        </form>
    </div>
</template>

<script>
    export default {
        name: "ContactForm",
        data() {
            return {
                errors: {},
                interestsChecked: [],
                productsSend: this.savedProducts.products,
                firstname: '',
                lastname: '',
                company: '',
                tax: '',
                email: '',
                phone: '',
                comments: '',
                url: '',
                privacy: false,
                messages: {},
                hideForm: false,
            }
        },
        methods: {
            removeProduct(index) {
                this.productsSend.splice(index, 1)
            },
            handleFormSubmit() {
                let data = new FormData(this.$refs.form);

                let productTitles = [];
                this.productsSend.forEach(product => {
                    productTitles.push([product.name, product.sku])
                });

                data.append("interests", this.interestsChecked);
                data.append("products", JSON.stringify(productTitles));
                data.append("js_url", "js_set");
                data.append("action", "contact");
                data.append("controller", "forms");

                window.axios
                    .post(this.routes.form_handle, data, {
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                            'Content-type': 'application/x-www-form-urlencoded',
                        }
                    })
                    .then(this.handleRes);
            },
            handleRes(res){
                this.errors = res?.data?.errorFields ?? {};
                this.messages = res?.data?.messages ?? {};

                if(res?.data?.hideForm)
                {
                    this.hideForm = true;
                }

                if(res?.data?.toTop)
                {
                    this.$refs.formSection.scrollIntoView({behavior: "smooth", block: "start"});
                }
            },
        },
        props: {
            routes: {
                type: Object|Array,
                required: true,
            },
            translations: {
                type: Object|Array,
                required: true
            },
            savedProducts: {
                type: Object|Array,
                required: false,
                default() { return " "; }
            },
            interests: {
                type: Object|Array,
                required: false,
                default() { return " "; }
            },
        }
    }
</script>

<style scoped>

</style>